<template>
  <b-card title="Feedback Forms">
      <b-button size="md" variant="primary" @click="add()" v-if="$can('add', 'questions')">
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        /><span>Add</span>
      </b-button>
    
    <!-- search input -->
    <div class="custom-search float-right">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            name="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <div v-if="!rows || isLoadingTable" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
    </div>
    <vue-good-table
      v-if="rows && !isLoadingTable"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <span
          v-if="props.column.field === 'url_formatted'"
          class="text-nowrap">
          <div v-if="props.row.event_booth !== null">
            <a :href="props.row.event_booth.feedback_url" target="_blank">
              {{ props.row.event_booth.feedback_url }}
            </a>          
          </div>
          <div v-else>
            <a :href="props.row.event.feedback_url" target="_blank">
              {{ props.row.event.feedback_url }}
            </a>  
          </div>

        </span>

        <!-- Column: Created by -->
        <span
          v-if="props.column.field === 'updated_by_user'"
          class="text-nowrap"
        >
          <span class="text-nowrap" v-if="props.row.updated_by !== null">{{ props.row.updated_by.name }}</span>
          <span class="text-nowrap" v-if="props.row.created_by !== null && props.row.updated_by === null">{{ props.row.created_by.name }}</span>
          <span class="text-nowrap" v-if="props.row.created_by === null && props.row.updated_by === null">-</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="viewResponses(props.row.event_id, props.row.event_booth_id)" v-if="$can('view', 'questions')">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Responses</span>
              </b-dropdown-item>
              <b-dropdown-item @click="editModal(props.row.event_id, props.row.event_booth_id)" v-if="$can('edit', 'questions')">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeModal(props.row.event_id, props.row.event_booth_id)" v-if="$can('delete', 'questions')">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  <!-- Modals -->
    <b-modal
      id="modal-delete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteModal"
    >
      <template #modal-header="{}">
        <h5>Delete {{ pageName }}</h5>
      </template>

      <p>
        Are you sure you want to delete this item?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="remove()" :disabled="isLoadingDelete">
          {{ (isLoadingDelete) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>

  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number'
        },
        {
          label: 'Event Name',
          field: 'event.name',
        },
        {
          label: 'Booth Name',
          field: 'event_booth.name',
        },
        {
          label: 'Date Start',
          field: 'event.date_start',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy',
        },
        {
          label: 'Date End',
          field: 'event.date_end',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy',
        },
        {
          label: 'URL',
          field: 'url_formatted',
        },
        // {
        //   label: 'Last Updated At',
        //   field: 'updated_at',
        //   type: 'date',
        //   dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
        //   dateOutputFormat: 'dd-MM-yyyy HH:mm',
        // },
        // {
        //   label: 'Last Updated By',
        //   field: 'updated_by_user',
        // },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',

      isLoadingTable: false,
      isLoadingForm: false,
      isLoadingDelete: false,
      formState: "Edit",
      isEdit: false,
      showFormModal: false,
      showDeleteModal: false,
      pageName: "Feedback Forms",

      currentObject: {
        eventId: -1,
        eventBoothId: -1
      }
    }
  },
  computed: {
  },
  methods: {
    viewResponses(eventId, eventBoothId) {
      this.$router.push("/master/questions/" + eventId + "/" + eventBoothId + "/responses")
    },
    add() {
        this.$router.push("/master/questions/add")
    },
    editModal(eventId, eventBoothId) {
        this.$router.push("/master/questions/" + eventId + "/" + eventBoothId + "/edit")

    },
    removeModal(eventId, eventBoothId) {      
      this.showDeleteModal = true

      this.currentObject.eventId = eventId
      this.currentObject.eventBoothId = eventBoothId
    },
    remove() {
      this.isLoadingDelete = true

      const eventId = this.currentObject.event_id
      const eventBoothId = this.currentObject.event_booth_id

      this.$http.post('/admin/v1/feedback_questions_delete', {
        event_id: eventId,
        event_booth_id: eventBoothId
      })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }

          this.showDeleteModal = false

          this.isLoadingDelete = false

          this.fetchData()
      })
    },
    fetchData() {
      this.isLoadingTable = true

      this.$http.get('/admin/v1/feedback_questions')
        .then(res => {         
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
          
            this.rows = currentData
          }

          this.isLoadingTable = false

     })
    },
  },
  created() {
    this.fetchData()
  },
}
</script>